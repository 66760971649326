import { parseDate } from "chrono-node";
import { startOfDay } from "date-fns";
import { ReactElement } from "react";
import { DateTimeFormatHandler } from "../../../hooks/useDateTimeFormatter";
import BulbIcon from "../../../img/bulb-icon.svg";
import CoffeeIcon from "../../../img/coffee.svg";
import CompassIcon from "../../../img/compass-icon.svg";
import ElectricIcon from "../../../img/lightning-bolt.svg";
import ChatIcon from "../../../img/message-square.svg";
import SendIcon from "../../../img/send-icon.svg";
import SmileIcon from "../../../img/smile.svg";
import SparkleIcon from "../../../img/sparkle-icon.svg";
import StarIcon from "../../../img/star-outline.svg";
import SunIcon from "../../../img/sun.svg";
import { DelayStart } from "../../../reclaim-api/scheduling-links/scheduling-links-client";
import {
  CreateMembershipRequest,
  CreateSchedulingLinkRequest,
  PatchSchedulingLinkRequest,
  SchedulingLink,
  SchedulingLinkIconType,
  TimePolicyType,
} from "../../../reclaim-api/scheduling-links/SchedulingLinks";
import { defaultTimePolicy, User } from "../../../reclaim-api/Users";
import { findAndPunch } from "../../../utils/arrays";
import { MILLISECONDS_PER_MINUTE } from "../../../utils/dates";
import { getTemplateKeyRegex } from "../../../utils/strings";
import { arrayOfAll } from "../../../utils/typescript";
import { StrongSelectItem } from "../StrongSelect";
import { SchedulingLinkFormFieldValues } from "./SchedulingLinkForm.types";
import { SchedulingLinkOrganizerPickerItemOrganizer } from "./SchedulingLinkOrganizerPickerItem";

const DEFAULT_MEETING_TITLE = "{organizer} and {attendee}";

export const defaultFieldValues = (user: User | undefined): SchedulingLinkFormFieldValues => {
  if (!user) throw new Error("User object was not defined.  Are you logged out?");
  const { email, id: userId, name, firstName, lastName, avatarUrl } = user;

  if (!userId) throw new Error("User did not have a user ID");
  if (!email) throw new Error("User did not have an email");

  return {
    title: "",
    description: "",
    durations: [30 * MILLISECONDS_PER_MINUTE],
    defaultDuration: undefined,
    daysIntoFuture: 60,
    startDate: undefined,
    endDate: undefined,
    priority: "DEFAULT",
    iconType: "LIGHT",
    slug: "",
    delayStartType: DelayStart.FROMNOWHOURS,
    delayStartUnits: 4,
    organizers: [
      {
        role: "MAIN_ORGANIZER",
        timePolicyType: "MEETING",
        userId,
        oneOffPolicy: defaultTimePolicy(),
        thinPerson: {
          email,
          avatarUrl,
          firstName,
          lastName,
          name,
          userId,
        },
      },
    ],
    linkGroupId: "root",
    meetingTitle: DEFAULT_MEETING_TITLE,
    locationOptions: [],
  };
};

export const schedulingLinkGroupIdToFieldValues = (
  link: SchedulingLink
): SchedulingLinkFormFieldValues["linkGroupId"] => {
  if (link.hidden) return "hidden";
  return link.linkGroupId || "root";
};

const fvOrgToMember = ({
  oneOffPolicy,
  timePolicyType,
  userId,
}: // Create is a subset of patch so we can shortcut and just use it here -SG
SchedulingLinkOrganizerPickerItemOrganizer): CreateMembershipRequest => ({
  userId,
  timePolicyType,
  oneOffPolicy: timePolicyType === "ONE_OFF" ? oneOffPolicy : undefined,
});

export const schedulingLinkToFieldValues = (
  format: DateTimeFormatHandler,
  link: SchedulingLink
): SchedulingLinkFormFieldValues => ({
  title: link.title || "",
  iconType: link.iconType,
  description: link.description || "",
  durations: link.durations.sort((a, b) => a - b).map((l) => l * MILLISECONDS_PER_MINUTE),
  defaultDuration: !!link.defaultDuration ? link.defaultDuration * MILLISECONDS_PER_MINUTE : undefined,
  daysIntoFuture: link.daysIntoFuture || undefined,
  startDate: !!link.startDate ? format(link.startDate, "DATE_YEAR_DISPLAY_FORMAT") : undefined,
  endDate: !!link.endDate ? format(link.endDate, "DATE_YEAR_DISPLAY_FORMAT") : undefined,
  priority: link.priority || "DEFAULT",
  slug: link.slug || "",
  locationOptions: link.locationOptions,
  delayStartType: !!link?.delayStartUnits ? link?.delayStart : DelayStart.FROMNOWHOURS,
  delayStartUnits: link?.delayStartUnits ? link?.delayStartUnits : 4,
  organizers:
    link.organizers?.map(({ organizer, timePolicyType, oneOffPolicy, role }) => {
      const { userId, email } = organizer;
      if (!userId) throw new Error(`Organizer "${email}" did not have a userId`);
      return {
        userId,
        timePolicyType,
        oneOffPolicy: oneOffPolicy || defaultTimePolicy(),
        role,
        thinPerson: organizer,
      };
    }) || [],
  linkGroupId: schedulingLinkGroupIdToFieldValues(link),
  meetingTitle: link.meetingTitle || DEFAULT_MEETING_TITLE,
});

export const fieldValuesToCreateSchedulingLinkRequest = (
  values: SchedulingLinkFormFieldValues
): CreateSchedulingLinkRequest => {
  const coOrganizers = [...values.organizers];
  const organizer = findAndPunch(coOrganizers, ({ role }) => role === "MAIN_ORGANIZER");
  if (!organizer) throw new Error("Could not find organizer");

  return {
    title: values.title,
    description: values.description,
    enabled: true,
    timePolicyType: organizer.timePolicyType,
    oneOffPolicy: organizer.timePolicyType === "ONE_OFF" ? organizer.oneOffPolicy : undefined,
    durations: values.durations.map((l) => l / MILLISECONDS_PER_MINUTE),
    defaultDuration: !!values.defaultDuration ? values.defaultDuration / MILLISECONDS_PER_MINUTE : undefined,
    daysIntoFuture: Number(values.daysIntoFuture) || undefined,
    startDate: !!values.startDate ? startOfDay(parseDate(values.startDate)) : undefined,
    endDate: !!values.endDate ? startOfDay(parseDate(values.endDate)) : undefined,
    priority: values.priority,
    iconType: values.iconType,
    slug: values.slug,
    delayStart: values.delayStartType,
    delayStartUnits: Number(values.delayStartUnits) || undefined,
    members: coOrganizers.map(fvOrgToMember),
    hidden: values.linkGroupId === "hidden",
    linkGroupId: (() => {
      switch (values.linkGroupId) {
        case "hidden":
        case "root":
          return undefined;
        default:
          return values.linkGroupId;
      }
    })(),
    meetingTitle: values.meetingTitle,
    locationOptions: values.locationOptions,
  };
};

export const resolveDerivativeSchedulingLinkTitle = (schedulingLink: SchedulingLink): string => {
  const hasAttendeeVariable = (s: string): boolean => s.indexOf("{attendee}") > -1;

  const { attendeeName, meetingTitle, organizerView } = schedulingLink;

  const organizerName = organizerView?.organizer?.name || "";

  if (!meetingTitle) return `Meeting with ${organizerName}`;

  if (attendeeName) {
    return meetingTitle
      .replace(getTemplateKeyRegex("organizer"), organizerName)
      .replace(getTemplateKeyRegex("attendee"), attendeeName);
  }

  return !hasAttendeeVariable(meetingTitle)
    ? meetingTitle.replace(getTemplateKeyRegex("organizer"), organizerName)
    : `Meeting with ${organizerName}`;
};

export const fieldValuesToPatchSchedulingLinkRequest = (
  values: SchedulingLinkFormFieldValues
): PatchSchedulingLinkRequest => {
  const coOrganizers = [...values.organizers];
  const organizer = findAndPunch(coOrganizers, ({ role }) => role === "MAIN_ORGANIZER");
  if (!organizer) throw new Error("Could not find organizer");

  return {
    delayStartUnits: Number(values.delayStartUnits) || null,
    title: values.title,
    description: values.description || null,
    timePolicyType: organizer.timePolicyType,
    oneOffPolicy: organizer.timePolicyType === "ONE_OFF" ? organizer.oneOffPolicy : undefined,
    durations: values.durations.map((l) => l / MILLISECONDS_PER_MINUTE),
    defaultDuration: !!values.defaultDuration ? values.defaultDuration / MILLISECONDS_PER_MINUTE : null,
    daysIntoFuture: Number(values.daysIntoFuture) || null,
    delayStart: values.delayStartType,
    startDate: !!values.startDate ? startOfDay(parseDate(values.startDate)) : null,
    endDate: !!values.endDate ? startOfDay(parseDate(values.endDate)) : null,
    priority: values.priority,
    iconType: values.iconType,
    slug: values.slug,
    members: coOrganizers.map(fvOrgToMember),
    hidden: values.linkGroupId === "hidden",
    linkGroupId: (() => {
      switch (values.linkGroupId) {
        case "hidden":
        case "root":
          return null;
        default:
          return values.linkGroupId;
      }
    })(),
    meetingTitle: values.meetingTitle,
    locationOptions: values.locationOptions,
  };
};

export const fieldValuesToPatchDerivativeSchedulingLinkRequest = (
  values: SchedulingLinkFormFieldValues
): CreateSchedulingLinkRequest => ({
  ...fieldValuesToCreateSchedulingLinkRequest(values),
  title: values.meetingTitle || "",
});

export const derivativeSchedulingLinkToFormFields = (
  format: DateTimeFormatHandler,
  link: SchedulingLink
): SchedulingLinkFormFieldValues => ({
  ...schedulingLinkToFieldValues(format, link),
  title: link.meetingTitle || "",
});

export type SchedulingLinkIconData<K extends SchedulingLinkIconType = SchedulingLinkIconType> = {
  key: K;
  icon: ReactElement;
};

export const SCHEDULING_LINKS_ICON_ORDER = arrayOfAll<SchedulingLinkIconType>()([
  "LIGHT",
  "COFFEE",
  "BOLT",
  "COMMENT",
  "STAR",
  "AIRPLANE",
  "TWINKLE",
  "COMPASS",
  "SUN",
  "SMILE",
]);

export const SCHEDULING_LINKS_ICON_MAP: { [K in SchedulingLinkIconType]: SchedulingLinkIconData<K> } = {
  LIGHT: { key: "LIGHT", icon: <BulbIcon /> },
  COFFEE: { key: "COFFEE", icon: <CoffeeIcon /> },
  BOLT: { key: "BOLT", icon: <ElectricIcon /> },
  COMMENT: { key: "COMMENT", icon: <ChatIcon /> },
  STAR: { key: "STAR", icon: <StarIcon /> },
  AIRPLANE: { key: "AIRPLANE", icon: <SendIcon /> },
  TWINKLE: { key: "TWINKLE", icon: <SparkleIcon /> },
  COMPASS: { key: "COMPASS", icon: <CompassIcon /> },
  SUN: { key: "SUN", icon: <SunIcon /> },
  SMILE: { key: "SMILE", icon: <SmileIcon /> },
};

export const SCHEDULING_LINKS_ICONS: SchedulingLinkIconData[] = SCHEDULING_LINKS_ICON_ORDER.map(
  (key) => SCHEDULING_LINKS_ICON_MAP[key]
);

export const timePolicyTypeSelectOptions = (): StrongSelectItem<TimePolicyType>[] => [
  { value: "MEETING", label: "Meeting" },
  { value: "WORK", label: "Working" },
  { value: "PERSONAL", label: "Personal" },
  { value: "ONE_OFF", label: "Custom" },
];
